import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import Layout from '../../layouts/Layout';

import { graphql, useStaticQuery } from 'gatsby';
import JobTable from '../../components/JobTable/JobTable';
// @ts-ignore
import back from '../../images/back.svg';

const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout text={'Search Results'} version={2} title={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'} desc={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'}>
			<div className={'container mx-auto px-5 py-6'}>
				<a href={'/'} className={'flex flex-row text-sl-blue'}>
					<img className={'pr-2'} src={back} alt={'back'} />
					Home
				</a>
			</div>
			<JobTable data={data}/>
		</Layout>
	);
};

export default SearchPage;

export const Head: HeadFC = () => <title>Home Page</title>;
